import firebase from "firebase/app";
import { useSelector } from "react-redux";
import { getProperty, websettingsData } from "../store/reducers/webSettings";
require("firebase/auth");
require("firebase/firestore");

const FirebaseData = () => {

    const websettingsdata = useSelector(websettingsData);

    const apiKeyData = websettingsdata && getProperty(websettingsdata, 'firebase_api_key');
    const authDomainData = websettingsdata && getProperty(websettingsdata, 'firebase_auth_domain');
    const databaseURLData = websettingsdata && getProperty(websettingsdata, 'firebase_database_url');
    const projectIdData = websettingsdata && getProperty(websettingsdata, 'firebase_project_id');
    const storageBucketData = websettingsdata && getProperty(websettingsdata, 'firebase_storage_bucket');
    const messagingSenderIdData = websettingsdata && getProperty(websettingsdata, 'firebase_messager_sender_id');
    const appIdData = websettingsdata && getProperty(websettingsdata, 'firebase_app_id');
    const measurementIdData = websettingsdata && getProperty(websettingsdata, 'firebase_measurement_id');

    let firebaseConfig = {

            apiKey: "AIzaSyA5ShOWEyp2lm9zOsU6RXvB-LN1LfQzw-I",
            authDomain: "afquestdynasty.firebaseapp.com",
            projectId: "afquestdynasty",
            storageBucket: "afquestdynasty.appspot.com",
            messagingSenderId: "767454490698",
            appId: "1:767454490698:web:3d6db2121740d3803eb6b3",
            measurementId: "G-877V0RVTQT"
    }

    // eslint-disable-next-line
    if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
    } else {
        firebase.app(); // if already initialized, use that one
    }

    const auth = firebase.auth();

    const db = firebase.firestore();

    const googleProvider = new firebase.auth.GoogleAuthProvider();

    const facebookprovider = new firebase.auth.FacebookAuthProvider();

    return ({ auth, googleProvider, facebookprovider, firebase, db });

}

export default FirebaseData;
